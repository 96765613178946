// Fonts
* {
  font-family: 'Montserrat';
  color: $black-color-1;
}

// Buttons
.dg-button-main {
  width: 175px;
  min-width: 100px !important;
  max-width: 175px !important;
  height: 50px;
  background-color: $bg-color-1;
  border-radius: 25px;
  border: none;
  color: white;
}

.dg-button-squared-variant {
  width: 100%;
  height: 50px;
  background-color: $bg-color-1;
  border-radius: 8px;
  border: none;
  color: white;
}

.dg-text-bold {
  font-weight: 500 !important;
}

.video-mask {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.react-player {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  outline: none;
  top: 0;
  left: 0;
  border-radius: 5px;
  overflow: hidden;
  &:hover {
    .video-controls-wrapper {
      visibility: visible;
    }
  }
}

.video-controls-wrapper {
  visibility: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}

.video-control-item {
  color: #777;
  font-size: 50;
  transform: scale(0.9);
  &:hover {
    color: white;
    transform: scale(1);
  }
}

.video-control-item-bottom {
  color: #999;
  &:hover {
    color: white;
  }
}

.volume-slider {
  width: 100px !important;
}

.transparent {
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  &:hover {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }
  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none !important;
  }
  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }
}

.justify-content-right {
  justify-content: right;
}

.text-primary {
  color: $link-color-1 !important;
}

.border-bottom-primary {
  border-bottom: 3px solid $link-color-1 !important;
}

.border-bottom-black {
  border-bottom: 3px solid $black-color-1 !important;
}

.border-bottom-black-md {
  border-bottom: 2px solid $black-color-1 !important;
}

.border-bottom-black-xs {
  border-bottom: 1px solid $black-color-1 !important;
}

.text-semi-bold {
  font-weight: 500 !important;
}

.text-bold {
  font-weight: 600 !important;
}

.text-x-bold {
  font-weight: 700 !important;
}

.text-medium {
  font-weight: 400 !important;
}

.heading-subtitle {
  font-weight: 500;
}

.text-light {
  color: $text-color-grey-1 !important;
}

.text-size-small {
  font-size: 1.25rem !important;
}

.text-size-xs {
  font-size: 1rem !important;
}

.line-height-medium {
  line-height: 2.25rem !important;
}

.line-height-big {
  line-height: 2.5rem !important;
}

.no-underline {
  text-decoration: none !important;
}

.pointer {
  &:hover {
    cursor: pointer !important;
  }
}

.border-radius-small {
  border-radius: 5px !important;
}

.height-350 {
  height: 350px !important;
}

.blog-section-card {
  height: 475px;
  min-height: 350px;
  width: 95%;
}

.maintenance {
  height: 100vh;
}

.fully {
  background-color: white;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.modal-banner {
  background-color: $bg-color-1;
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  border-radius: 4px;
  & h2 {
    font-size: 2rem !important;
    font-weight: 700 !important;
  }
}

.border-none {
  border: none !important;
}

.circle {
  height: 25px;
  width: 25px;
  background-color: $bg-color-1;
  border-radius: 50%;
  &:nth-child(1) {
    margin-right: 0.25rem;
  }
}

.modal-body {
  min-height: 500px;
  max-height: 600px;
}

.opacity-35 {
  opacity: 0.35 !important;
}

.public-footer {
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
}

.contact-link-text {
  padding-left: 1rem !important;
}

.banner-icon {
  padding-left: 1rem !important;
}

.dg-input {
  padding: 0.75rem;
}

.dg-textarea {
  height: 150px;
}
