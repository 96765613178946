body {
  background-color: $bg-color-2;
  overflow-x: hidden;
}

nav {
  a {
    font-size: 0.85rem !important;
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.9) !important;
    &:hover {
      color: rgba(0, 0, 0, 0.9) !important;
    }
  }

  span {
    a {
      font-weight: 400 !important;
    }
  }
}

#homepage {
  h1 {
    font-size: 2.75rem;
    font-weight: 700 !important;
  }

  p {
    font-size: 1.35rem;
    font-weight: 300 !important;
  }
}

.container {
  @media (min-width: 1400px) {
    max-width: 1100px !important;
  }
}
