// Source: https://gist.github.com/bartholomej/8415655

/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet 
   480-less    - phone landscape & smaller
--------------------------------------------*/
@media all and (max-width: 767px) {
  // .blog-section {
  //   margin-top: 57.5rem !important;
  // }
  .carousel-button-group {
    justify-content: center !important;
  }
  .maintenance {
    & img {
      width: 50% !important;
    }
  }
}

@media all and (min-width: 768px) and (max-width: 992px) {
  // .blog-section {
  //   margin-top: 54rem !important;
  // }
}

@media all and (min-width: 992px) and (max-width: 1024px) {
  .blog-section-card {
    height: 575px !important;
  }
}

@media all and (min-width: 440px) and (max-width: 764px) {
  .blog-section-card {
    height: 550px !important;
  }
}
@media all and (min-width: 339px) and (max-width: 439px) {
  .blog-section-card {
    & img {
      height: 200px;
    }
  }
}

@media all and (max-width: 321px) {
  .navbar-brand {
    width: 50%;
    & img {
      width: 50% !important;
    }
  }
}

@media all and (max-width: 430px) {
  .video-controls-wrapper .bottom-controls {
    display: none !important;
  }
  .video-controls-copy {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
